import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import MenuIcon from '@material-ui/icons/Menu';

type Anchor = 'top' | 'left' | 'bottom' | 'right';

export interface SideDrawerProps {
    anchor: Anchor;
    children: React.ReactElement;
  }

const useStyles = makeStyles({
  root: {
    marginBottom: 25,
  },
  list: {
    width: 250,
  },
  fullList: {
    width: 'auto',
  },
  rightcol: {
    position: "fixed",
    right: "1rem",
  },
});

export const SideDrawer: React.FC<SideDrawerProps> = ({
  anchor, children
}) => {
  const classes = useStyles();
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const toggleDrawer = (anchor: Anchor, open: boolean) => (
    event: React.KeyboardEvent | React.MouseEvent,
  ) => {
    if (
      event.type === 'keydown' &&
      ((event as React.KeyboardEvent).key === 'Tab' ||
        (event as React.KeyboardEvent).key === 'Shift')
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const list = (anchor: Anchor) => (
    <div
      className={clsx(classes.list, {
        [classes.fullList]: anchor === 'top' || anchor === 'bottom',
      })}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      {children}
    </div>
  );

  return (
    <div className={classes.root}>
      <React.Fragment key={anchor}>
        <MenuIcon className={classes.rightcol} onClick={toggleDrawer(anchor, true)}/>
        <Drawer anchor={anchor} open={state[anchor]} onClose={toggleDrawer(anchor, false)}>
          {list(anchor)}
        </Drawer>
      </React.Fragment>
    </div>
  );
}
