import { Theme, createStyles, makeStyles } from "@material-ui/core/styles"
import { Link } from "gatsby"
import { FluidObject } from "gatsby-image"
import React from "react"
import Image from "../image"
import WhatsAppIcon from "@material-ui/icons/WhatsApp"
import InstagramIcon from "@material-ui/icons/Instagram"
import { Button } from "@material-ui/core"
import { Grid } from "@material-ui/core"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      background: theme.palette.secondary.main,
      // position: "absolute",
      width: "100%",
      padding: "1rem",
      paddingBottom: 0,
    },
    copyright: {
      // textAlign: "center",
      color: "lightgray",
      background: "inherit",
      fontSize: "1em",
    },
    social: {
      margin: "1.5rem",
    },
    button: {
      textDecoration: "none",
      margin: "0.2rem",
      width: 180
    },
    logo: {
      width: "200px",
      margin: "2rem",
      marginLeft: "auto",
      marginRight: "auto",
    },
  })
)

export interface FooterProps {
  author: string
  logo: FluidObject
}

export const Footer: React.FC<FooterProps> = ({ author, logo }) => {
  const classes = useStyles()

  return (
    <Grid
      container
      alignItems="center"
      justify="center"
      className={classes.root}
    >
      <Grid container alignItems="center" justify="space-between">
        <Grid container item xs={12} sm={4} alignItems="center" justify="center">
          <Grid container item xs alignItems="center" justify="center">
            <Link to="/">
              <Image fluid={logo} alt="Logo" className={classes.logo} />
            </Link>
          </Grid>
        </Grid>
        <Grid
          container
          item
          xs={12}
          sm={4}
          direction="column"
          alignItems="center"
          justify="center"
          className={classes.social}
        >
          <Grid>
            <a
              href="https://www.instagram.com/where.again/"
              target="_blank"
              rel="noreferrer"
            >
              <Button
                color="primary"
                className={classes.button}
                startIcon={<InstagramIcon />}
              >
                Instagram
              </Button>
            </a>
          </Grid>
          <Grid container justify="center">
            <a
              href="https://wa.me/380639891253/"
              target="_blank"
              rel="noreferrer"
            >
              <Button
                color="primary"
                className={classes.button}
                startIcon={<WhatsAppIcon />}
              >
                WhatsApp
              </Button>
            </a>
          </Grid>
        </Grid>
      </Grid>
      <Grid container alignItems="center" justify="center">
        <div className={classes.copyright}>© 2021 {author}</div>
      </Grid>
    </Grid>
  )
}
