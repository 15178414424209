import React from "react"
import { useState, useEffect } from "react"

import { createStyles, makeStyles, Theme } from "@material-ui/core/styles"
import AppBar from "@material-ui/core/AppBar"
import Toolbar from "@material-ui/core/Toolbar"
import useScrollTrigger from "@material-ui/core/useScrollTrigger"

import { useStaticQuery, graphql } from "gatsby"
import { SideDrawer } from "../side-drawer/side-drawer"

import List from "@material-ui/core/List"
import ListItem from "@material-ui/core/ListItem"
import Link from "@material-ui/core/Link"

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      marginBottom: "1.45rem",
      fontSize: "1.2em",
    },
    appbar: {
      backgroundColor: "white",
    },
    rightcol: {
      border: "inherit",
      backgroundColor: "inherit",
      color: "inherit",
      position: "fixed",
      right: "1rem",
    },
    link: {
      marginRight: "1rem",
    },
    logowrapper: {
      height: "60px",
    },
    logo: {
      width: "150px",
      height: "41px",
      margin: "0.5rem",
      // marginLeft: "0rem",
    },
    logodesktop: {
      width: "150px",
      height: "41px",
      margin: "0.5rem",
    },
    drawerlist: {
      fontSize: "1.2em",
      color: theme.palette.primary.main,
    },
    logodrawer: {
      width: "150px",
      height: "41px",
      margin: "1.5rem",
      marginLeft: "2.5rem",
    },
    mobiletoolbar: {
      color: theme.palette.primary.main,
      display: "flex",
    },
  })
)

interface Bar {
  home: string
  tours: string
  login: string
}

const displayMobile = ({ data }, bar: Bar) => {
  const classes = useStyles()

  return (
    <Toolbar className={classes.mobiletoolbar}>
      <Link href="/" style={{ height: "60px" }}>
        <img
          className={classes.logo}
          src={data.placeholderImage.childImageSharp.fluid.srcWebp}
          alt="Logo"
        />
      </Link>
      <SideDrawer anchor="right">
        <React.Fragment>
          <Link href="/" style={{ height: "60px" }}>
            <img
              className={classes.logodrawer}
              src={data.placeholderImage.childImageSharp.fluid.srcWebp}
              alt="Logo"
            />
          </Link>
          <List className={classes.drawerlist}>
            <ListItem button component="a" href="/">
              {bar.home}
            </ListItem>
            <ListItem button component="a" href="/tours/">
              {bar.tours}
            </ListItem>
            {/* <ListItem button component="a" href="/login/">Login</ListItem> */}
          </List>
        </React.Fragment>
      </SideDrawer>
    </Toolbar>
  )
}

const displayDesktop = ({ data }, bar: Bar) => {
  const classes = useStyles()
  return (
    <Toolbar className={classes.mobiletoolbar}>
      <Link href="/" className={classes.logowrapper}>
        <img
          className={classes.logodesktop}
          src={data.placeholderImage.childImageSharp.fluid.srcWebp}
          alt="Logo"
        />
      </Link>
      <div className={classes.rightcol}>
        <Link className={classes.link} href="/">
          {bar.home}
        </Link>
        <Link className={classes.link} href="/tours/">
          {bar.tours}
        </Link>
        {/* <Link className={classes.link} href="/login/">Login</Link> */}
      </div>
    </Toolbar>
  )
}

interface ESProps {
  children: React.ReactElement
}

const ElevationScroll: React.FC<ESProps> = ({ children }) => {
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
  })

  return React.cloneElement(children, {
    elevation: trigger ? 4 : 0,
    style: { backgroundColor: trigger ? "white" : "rgb(255,255,255,0.5)" },
  })
}

export default function Header() {
  const classes = useStyles()

  const [state, setState] = useState({
    mobileView: false,
  })
  const { mobileView } = state

  useEffect(() => {
    const setResponsiveness = () => {
      return window.innerWidth < 900
        ? setState(prevState => ({ ...prevState, mobileView: true }))
        : setState(prevState => ({ ...prevState, mobileView: false }))
    }
    setResponsiveness()
    window.addEventListener("resize", () => setResponsiveness())
  }, [])

  const data = useStaticQuery(graphql`
    query {
      placeholderImage: file(relativePath: { eq: "LogoTeal@1x.png" }) {
        childImageSharp {
          fluid(maxWidth: 200) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  const Bar = {
    home: "Головна",
    tours: "Тури",
    login: "Login",
  }

  return (
    <div className={classes.root}>
      <ElevationScroll>
        <AppBar position="fixed" className={classes.appbar}>
          {mobileView
            ? displayMobile({ data }, Bar)
            : displayDesktop({ data }, Bar)}
        </AppBar>
      </ElevationScroll>
    </div>
  )
}
