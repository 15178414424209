/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import Header from "../header/header"
import { Footer } from "../footer/footer"
import "./layout.css"

const Layout = ({ children, fullpage }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
          description
          author
        }
      }
      placeholderImage: file(relativePath: { eq: "LogoTeal@1x.png" }) {
        childImageSharp {
          fluid(maxWidth: 600) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <>
      <Header/>
      {/* Follows the style of the body */}
      <div
        style={{
          margin: fullpage? '0' : `5rem auto`,
          maxWidth: fullpage? '100%' : 960,
          marginLeft: 'auto',
          marginRight: 'auto',
          padding: fullpage? '0' : `0 1.0875rem 1.45rem`,
        }}
      >
        {/* Here whatever the layout is applied to */}
        <main>{children}</main>
      </div>
      {/* Finally the footer for all pages */}
      <Footer 
        author={data.site.siteMetadata.author}
        logo={data.placeholderImage.childImageSharp.fluid}/>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  fullpage: PropTypes.bool
}

Layout.defaultProps = {
  fullpage: false
};

export default Layout
